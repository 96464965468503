// @ts-nocheck

import jwtDecode from "jwt-decode";

import { ERole } from "../../shared/enums/protectedContent/Roles.enum";

import { getCookie, removeCookie, setCookie } from "./cookie";

const AUTH_COOKIE_NAME = "access_token_admin";
const ORIGINAL_AUTH_COOKIE_NAME = "original_access_token_admin";

/**
 * Fetches current Auth Token
 *
 * @returns {*|null}
 */
export const getAuthToken = () => getCookie(AUTH_COOKIE_NAME) || null;

/**
 * Fetches original Auth Token when user is impersonated
 *
 * @returns {*|null}
 */
export const getOriginalAuthToken = () =>
    getCookie(ORIGINAL_AUTH_COOKIE_NAME) || null;

/**
 * Persists Auth Token
 *
 * @param token
 */
export const setAuthToken = (token: string) => {
    const expires = _getFromAccessToken("exp", token);
    setCookie(AUTH_COOKIE_NAME, token, {
        path: "/",
        expires: new Date(expires * 1000),
        secure: "production" === process.env.NODE_ENV,
    });
};

/**
 * Removes Auth token -> logs out user
 *
 * @returns {*}
 */
export const clearAuthToken = () => removeCookie({ name: AUTH_COOKIE_NAME });

/**
 * Removes Original Auth token -> logs out user
 *
 * @returns {*}
 */
export const clearOriginalAuthToken = () =>
    removeCookie({ name: ORIGINAL_AUTH_COOKIE_NAME });

/**
 * Checks if current user is authenticated
 *
 * @returns {boolean}
 */
export const isAuthenticated = () => !!getAuthToken();

/**
 * Fetches initials of currently signed in user
 *
 * @returns {*|string}
 */
export const getUserInitals = () =>
    _getFromAccessToken("initials", getAuthToken()) || "";

/**
 * Fetches username of currently signed in user
 *
 * @returns {*|string}
 */
export const getUsername = () =>
    _getFromAccessToken("username", getAuthToken()) || "";

/**
 * Fetches username of original user (first connected before impersonate)
 *
 * @returns {*|string}
 */
export const getOriginalUsername = () =>
    _getFromAccessToken("username", getOriginalAuthToken()) || "";

/**
 * Fetches hashed username of currently signed in user
 *
 * @returns {*|string}
 */
export const getHashedUsername = () =>
    _getFromAccessToken("hashedUsername", getAuthToken()) || null;

/**
 * Fetches id of currently signed in user
 *
 * @returns {int|null}
 */
export const getUserId = () =>
    _getFromAccessToken("id", getAuthToken()) || null;

/**
 * Fetches username of currently signed in user
 *
 * @returns {*|string}
 */
export const isInitialPasswordChanged: GenericFunction<
    void,
    string | null
> = () => _getFromAccessToken("initialPasswordChanged", getAuthToken()) || "";

/**
 * Fetches locale of currently signed in user
 *
 * @returns {*|string}
 */
export const getLanguage = () =>
    _getFromAccessToken("language", getAuthToken()) || null;

/**
 * Checks if current user is a service provider
 *
 * @returns {*|boolean}
 */
export const isServiceProvider = () =>
    _getFromAccessToken("isServiceProvider", getAuthToken()) || false;

/**
 * Checks if current user is an affiliate
 *
 * @returns {*|boolean}
 */
export const isAffiliate = () =>
    _getFromAccessToken("isAffiliate", getAuthToken()) || false;

/**
 * Gets roles of current user
 *
 * @returns {*|array}
 */
export const getRoles = () =>
    _getFromAccessToken("roles", getAuthToken()) || [];

/**
 * Gets roles of current original user
 *
 * @returns {*|array}
 */
export const getRolesFromOriginalUser = () =>
    _getFromAccessToken("roles", getOriginalAuthToken()) || [];

/**
 * Gets roles of current original user
 *
 * @returns {*|array}
 */
export const getServiceProviderTypes = (): string[] =>
    _getFromAccessToken("serviceProviderTypes", getAuthToken()) || [];

/**
 * Gets registration scope of current original user
 *
 * @returns {*|string}
 */
export const getRegistrationScope = () =>
    _getFromAccessToken("registrationScope", getAuthToken()) || null;

/**
 * Checks if current user has given role
 *
 * @returns boolean
 */
export const hasRole = (role: ERole): boolean => {
    return getRoles().includes(role);
};

/**
 * Checks if current original user has given role
 *
 * @returns boolean
 */
export const originalUserHasRole = (role: string): boolean =>
    getRolesFromOriginalUser().includes(role);

/**
 * Fetches data from access token
 *
 * @param propertyName
 * @param token
 */
export const _getFromAccessToken = (
    propertyName: string,
    token: string,
): null | unknown => {
    if ("" === token || !token || !propertyName) {
        return null;
    }
    const decodedToken = jwtDecode(token) as any;
    return decodedToken[propertyName];
};
