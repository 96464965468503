import { z } from "zod";

const userSchema = z.object({
    id: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    language: z.string(),
});

type User = z.infer<typeof userSchema>;

export { userSchema };
export type { User };
