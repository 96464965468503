/*
 ** IMPORTANT **
 if you change the query key from "getCurrentUser" to sth else
   please don't forget to update the check in
   "src/contexts/network-client/index.tsx"
 */
import { gql } from "~/modules/gql";

const getCurrentUserGqlStr = gql(/* GraphQL */ `
    query getCurrentUser {
        currentUser {
            id
            firstname
            lastname
            language
        }
    }
`);

export { getCurrentUserGqlStr };
