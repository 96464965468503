import { LazyMotion } from "framer-motion";

import { IsHydratedProvider } from "~/contexts/is-hydrated";
import { ModalRootRefProvider } from "~/contexts/modal-root-ref";
import { NetworkClientProvider } from "~/contexts/network-client";
import { ThemeStoreProvider } from "~/contexts/theme-store";

import type { ThemeStore } from "~/contexts/theme-store";

interface Props {
    children?: React.ReactNode;
    modalRootRef: React.RefObject<HTMLElement>;
    isHydrated: boolean;
    themeStore: ThemeStore;
    axios?: Parameters<typeof NetworkClientProvider>[0]["axios"];
    gql?: Parameters<typeof NetworkClientProvider>[0]["gql"];
}

const loadFeatures = () =>
    import("./__internals__/lazy-motion-features").then((res) => res.domMax);

const MasterProvider: React.FC<Props> = ({
    children,
    modalRootRef,
    isHydrated,
    themeStore,
    axios,
    gql,
}) => {
    return (
        <LazyMotion features={loadFeatures} strict>
            <NetworkClientProvider axios={axios} gql={gql}>
                <IsHydratedProvider isHydrated={isHydrated}>
                    <ModalRootRefProvider modalRootRef={modalRootRef}>
                        <ThemeStoreProvider store={themeStore}>
                            {children}
                        </ThemeStoreProvider>
                    </ModalRootRefProvider>
                </IsHydratedProvider>
            </NetworkClientProvider>
        </LazyMotion>
    );
};

export { MasterProvider };
