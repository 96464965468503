import Cookies from "cookie-universal";
import dayjs from "dayjs";

import {
    ICookieOptions,
    ICookieValues,
} from "../interfaces/CookieOptions.interface";

const cookies = new (Cookies as any)();

/**
 * Keeps the cookies values inside of one request
 */
const cookieValues: ICookieValues = {};

/**
 * Fetches value from cookie. Looks in cookieValues first
 *
 * @param name
 *
 * @returns {*}
 */
export const getCookie = (name: string): string =>
    cookieValues[name] || cookies.get(name);

/**
 * Sets cookies
 *
 * @param name
 * @param value
 * @param options
 */
export const setCookie = (
    name: string,
    value: string,
    options: Partial<ICookieOptions> = {},
): boolean => {
    cookies.set(name, value, _getOptions(options));

    cookieValues[name] = value;

    return true;
};

/**
 * Removes a cookie from
 *
 * @param name
 * @param options
 */
export const removeCookie = (
    { name }: { name: string },
    options: Partial<ICookieOptions> = {},
): boolean => {
    cookies.remove(name, _getOptions(options));

    delete cookieValues[name];

    return true;
};

const defaultOptions: ICookieOptions = {
    path: "/",
    expires: dayjs().add(30, "day").toDate(),
    secure: "production" === process.env.NODE_ENV,
};

const _getOptions = (options: Partial<ICookieOptions>): ICookieOptions => ({
    ...defaultOptions,
    ...options,
});
