import { httpBatchLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type AppRouter } from "server/api/root";

export const nextApi = createTRPCNext<AppRouter>({
    config() {
        return {
            links: [
                httpBatchLink({
                    url: `/api/trpc`,
                }),
            ],
        };
    },
});
