import {
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";

import { useNetworkClient } from "~/contexts/network-client";
import { throwErrResult } from "~/modules/throw-err-result";

import { getQueryKey } from "./get-query-key";
import { getCurrentUser } from "./query-fn";

import type { QueryErr, QueryOk } from "./query-fn";

function useUser(
    options: UseQueryOptions<QueryOk, QueryErr> = {},
): UseQueryResult<QueryOk, QueryErr> {
    const { gql } = useNetworkClient();
    const queryResult = useQuery<QueryOk, QueryErr>(
        getQueryKey(),
        async () => {
            return throwErrResult(await getCurrentUser(gql));
        },
        {
            ...options,
            staleTime: 1000 * 60 * 60,
            suspense: false,
            useErrorBoundary: false,
        },
    );
    return queryResult;
}

export { useUser };
