import { ClientError } from "graphql-request";
import { err, ok, Result } from "neverthrow";

import { User, userSchema } from "~/data-types/user";

import { getCurrentUserGqlStr } from "./graphql-definitions";

import type { GraphQLClient } from "graphql-request";

type QueryOk = User;
type QueryErr = "unauthenticated" | "generic";

async function getCurrentUser(
    gql: GraphQLClient,
): Promise<Result<QueryOk, QueryErr>> {
    try {
        const result = await gql.request(getCurrentUserGqlStr);
        const parsedUser = userSchema.parse(result.currentUser);
        return ok(parsedUser);
    } catch (e) {
        if (e instanceof ClientError && e.response.status == 401) {
            return err("unauthenticated");
        }
        return err("generic");
    }
}

export { getCurrentUser };
export type { QueryErr, QueryOk };
