import { Button } from "@kruu-gitlab/kruuponents/button";
import { Input } from "@kruu-gitlab/kruuponents/input";
import { TableCell, TableRow } from "@kruu-gitlab/kruuponents/table";
import { useState } from "react";

import { Icon } from "~/components/icon";
import { nextApi } from "~/modules/next-api";

import { getMissingTranslationUploadButtonId } from "./get-missing-translation-upload-button-id";

import type { MissingTranslation } from "./store";

interface Props {
    missingTranslation: MissingTranslation;
    disableSave?: boolean;
}

const MissingTranslationRow: React.FC<Props> = ({
    missingTranslation: mt,
    disableSave,
}) => {
    const upsertMutation = nextApi.translations.writeToEnLocale.useMutation();
    const [defaultValue, setDefaultValue] = useState("");

    return (
        <TableRow>
            <TableCell>
                <Button
                    variant="clear"
                    id={getMissingTranslationUploadButtonId(mt)}
                    className="min-w-0 rounded-full p-2"
                    disabled={disableSave}
                    color="surface-inverted"
                    onClick={() => {
                        upsertMutation.mutate({
                            key: mt.key,
                            namespace: mt.ns,
                            value: defaultValue,
                        });
                    }}
                    loading={upsertMutation.isLoading}
                >
                    <Icon
                        icon="material-symbols:cloud-upload"
                        className="h-6 w-6"
                    />
                </Button>
            </TableCell>
            <TableCell>{mt.ns}</TableCell>
            <TableCell>{mt.key}</TableCell>
            <TableCell className="w-full">
                <Input
                    color="primary"
                    className="w-full"
                    value={defaultValue}
                    disabled={disableSave}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDefaultValue(e.target.value);
                    }}
                />
            </TableCell>
            <TableCell>
                {mt.url.map((s) => {
                    return (
                        <p key={s} className="whitespace-nowrap">
                            {s}
                        </p>
                    );
                })}
            </TableCell>
        </TableRow>
    );
};

export { MissingTranslationRow };
