import { createContext, useContext } from "react";
import { z } from "zod";
import { create, useStore } from "zustand";
import { persist } from "zustand/middleware";

const themeStoreValuesSchema = z.object({
    theme: z.enum(["light", "dark", "auto"]),
});

type Theme = z.infer<typeof themeStoreValuesSchema.shape.theme>;

interface ThemeStoreItems extends z.infer<typeof themeStoreValuesSchema> {
    setTheme: (theme: Theme) => void;
}

function createThemeStore() {
    return create(
        persist<ThemeStoreItems>(
            (set) => ({
                theme: "auto",
                setTheme: (theme) => set({ theme: theme }),
            }),
            {
                name: "theme",
                storage: {
                    getItem: (name) => {
                        const savedState = JSON.parse(
                            localStorage.getItem(name) || "",
                        );

                        themeStoreValuesSchema.parse(savedState["state"]);

                        return savedState;
                    },
                    setItem: (name, value) => {
                        localStorage.setItem(name, JSON.stringify(value));
                    },
                    removeItem: (name) => localStorage.removeItem(name),
                },
            },
        ),
    );
}

type ThemeStore = ReturnType<typeof createThemeStore>;

const ThemeStoreContext = createContext<ThemeStore | null>(null);

interface Props {
    children: React.ReactNode;
    store: ThemeStore;
}

const ThemeStoreProvider: React.FC<Props> = ({ children, store }) => {
    return (
        <ThemeStoreContext.Provider value={store}>
            {children}
        </ThemeStoreContext.Provider>
    );
};

function useThemeStore(): ReturnType<typeof useStore<ThemeStore>>;
function useThemeStore<U>(
    selector: Parameters<typeof useStore<ThemeStore, U>>["1"],
): ReturnType<typeof useStore<ThemeStore, U>>;
function useThemeStore(s?: any) {
    const store = useContext(ThemeStoreContext);

    if (!store) {
        throw new Error(
            "ThemeStoreProvider was not found in context. Did you forget to wrap your components with ThemeStoreProvider?",
        );
    }

    const values = useStore(store, s);

    return values;
}

export { createThemeStore, ThemeStoreProvider, useThemeStore };
export type { ThemeStore };
