import { produce } from "immer";
import { create } from "zustand";

interface MissingTranslation {
    key: string;
    ns: string;
    url: string[];
}

type NewMissingTranslation = Omit<MissingTranslation, "url"> & { url?: string };

interface MissingTranslationStoreItems {
    addMissingTranslation: (mt: NewMissingTranslation) => void;
    removeMissingTranslation: (mt: MissingTranslation) => void;
    missingTranslations: MissingTranslation[];
}

const missingTranslationStore = create<MissingTranslationStoreItems>((set) => ({
    missingTranslations: [],
    addMissingTranslation: (mt) => {
        set((state) => {
            const index = state.missingTranslations.findIndex((smt) => {
                return smt.ns == mt.ns && smt.key == mt.key;
            });

            return {
                missingTranslations: produce(
                    state.missingTranslations,
                    (draft) => {
                        if (index == -1) {
                            draft.push({ ...mt, url: mt.url ? [mt.url] : [] });
                        } else {
                            if (mt.url) {
                                if (
                                    !draft[index].url.find((u) => u == mt.url)
                                ) {
                                    draft[index].url.push(mt.url);
                                }
                            }
                        }
                    },
                ),
            };
        });
    },
    removeMissingTranslation: (mt) => {
        set((state) => {
            return {
                missingTranslations: state.missingTranslations.filter((smt) => {
                    return smt.ns != mt.ns || smt.key != mt.key;
                }),
            };
        });
    },
}));

function addMissingTranslation(mt: NewMissingTranslation) {
    missingTranslationStore.getState().addMissingTranslation(mt);
}

function removeMissingTranslation(mt: MissingTranslation) {
    missingTranslationStore.getState().removeMissingTranslation(mt);
}

export {
    addMissingTranslation,
    missingTranslationStore,
    removeMissingTranslation,
};

export type { MissingTranslation };
