import { Result } from "neverthrow";

function throwErrResult<T>(result: Result<T, unknown>): T {
    if (result.isOk()) {
        return result.value;
    }
    throw result.error;
}

export { throwErrResult };
