import { isBrowser } from "browser-or-node";
import { i18n } from "locale-config/config";
import process from "process";

import { addMissingTranslation } from "~/components/dev-tools/missing-translations";

import type { UserConfig } from "next-i18next";

const SHOW_KEYS =
    process.env["NEXT_PUBLIC_I18NEXT_ALWAYS_RETURN_KEY"] === "true";

const nextI18NextConfig: UserConfig = {
    appendNamespaceToMissingKey: true,
    i18n,
    fallbackLng: false,
    localePath: "./locales",
    reloadOnPrerender: process.env.NODE_ENV === "development",
    serializeConfig: false,
    saveMissing: true,
    resources: SHOW_KEYS
        ? Object.fromEntries(
              i18n.locales.map((locale) => {
                  return [locale, {}];
              }),
          )
        : undefined,
    missingKeyHandler: isBrowser
        ? (lngs, ns, key, fallbackValue, updateMissing, options) => {
              console.log("missingKeyHandler lngs: ", lngs);
              console.log("missingKeyHandler ns: ", ns);
              console.log("missingKeyHandler key: ", key);
              console.log("missingKeyHandler fallbackValue: ", fallbackValue);
              console.log("missingKeyHandler updateMissing: ", updateMissing);
              console.log("missingKeyHandler options: ", options);

              let keyWithoutNs = key;

              if (keyWithoutNs.startsWith(`${ns}:`)) {
                  keyWithoutNs = keyWithoutNs.replace(`${ns}:`, "");
              }

              addMissingTranslation({
                  key: keyWithoutNs,
                  ns,
                  url: isBrowser ? window.location.href : undefined,
              });
          }
        : undefined,
};

export { nextI18NextConfig };
