import { createContext, useContext } from "react";

const IsHydratedContext = createContext<boolean>(false);

interface Props {
    children?: React.ReactNode;
    isHydrated: boolean;
}

const IsHydratedProvider: React.FC<Props> = ({ isHydrated, children }) => {
    return (
        <IsHydratedContext.Provider value={isHydrated}>
            {children}
        </IsHydratedContext.Provider>
    );
};

function useIsHydrated(): boolean {
    const isHydrated = useContext(IsHydratedContext);
    if (isHydrated == null) {
        throw new Error(
            "HydratedContext not found. Did you forget to wrap your components with IsHydratedProvider?",
        );
    }
    return isHydrated;
}

export { IsHydratedProvider, useIsHydrated };
