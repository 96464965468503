import { Icon as IconifyReactWrapper } from "@iconify-icon/react";
import { clsx } from "clsx";
import { forwardRef } from "react";

import type {
    IconifyIconHTMLElement,
    IconifyIconProps,
} from "@iconify-icon/react";
import type { PropsWithoutRef } from "react";

const Icon = forwardRef<
    IconifyIconHTMLElement,
    PropsWithoutRef<IconifyIconProps>
>((props, ref) => {
    return (
        <IconifyReactWrapper
            ref={ref}
            width="100%"
            height="100%"
            {...props}
            className={clsx("h-[1.5rem] w-[1.5rem]", props.className)}
        />
    );
});
Icon.displayName = "Icon";
export { Icon };
