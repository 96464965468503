import { v4 as uuidv4 } from "uuid";

const prefix = uuidv4();

import type { MissingTranslation } from "./store";

function getMissingTranslationUploadButtonId(mt: MissingTranslation) {
    return `${prefix}-${mt.ns}-${mt.key}`;
}

export { getMissingTranslationUploadButtonId };
