import "~/styles/global.css";

import { KruuponentProvider } from "@kruu-gitlab/kruuponents/provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { clsx } from "clsx";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { nextI18NextConfig } from "next-i18next.config";
import { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useStore } from "zustand";

import { MissingTranslationsDevTools } from "~/components/dev-tools/missing-translations";
import { MasterProvider } from "~/contexts/master-provider";
import { createThemeStore } from "~/contexts/theme-store";
import { nextApi } from "~/modules/next-api";
import { useMqTheme } from "~/modules/use-mq-theme";
import { ExtendedAppProps } from "~/types/nextjs/extended-app-props";

const DirtyApp: React.FC<ExtendedAppProps> = ({
    Component,
    pageProps: pageProps,
}) => {
    const [isHydrated, setHydrated] = useState(false);
    useEffect(() => {
        setHydrated(true);
    }, []);

    const modalRootRef = useRef<HTMLDivElement>(null);
    const popoverRootRef = useRef<HTMLDivElement>(null);

    const [queryClient] = useState(() => {
        return new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: false,
                },
            },
        });
    });

    const [themeStore] = useState(() => {
        return createThemeStore();
    });

    const theme = useStore(themeStore, (s) => s.theme);
    const autoTheme = useMqTheme();

    const withLayout = Component.getLayout ?? ((page) => page);
    const withGuard = Component.getGuard ?? ((page) => page);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Head>
            <QueryClientProvider client={queryClient}>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <MasterProvider
                    isHydrated={isHydrated}
                    modalRootRef={modalRootRef}
                    themeStore={themeStore}
                >
                    <KruuponentProvider
                        modalRootRef={modalRootRef}
                        popoverRootRef={popoverRootRef}
                    >
                        <div
                            className={clsx(
                                isHydrated && {
                                    "dark-theme-vars":
                                        theme == "dark" ||
                                        (theme == "auto" &&
                                            autoTheme == "dark"),
                                    "light-theme-vars":
                                        theme == "light" ||
                                        (theme == "auto" &&
                                            autoTheme == "light"),
                                },
                                "font-catamaran",
                                "bg-surface-weak text-surface-contrast transition-colors duration-200 selection:bg-surface-contrast selection:text-surface",
                                "h-screen w-screen",
                            )}
                        >
                            <div className="h-full w-full overflow-y-auto">
                                {
                                    <>
                                        {withGuard(
                                            withLayout(
                                                <Component {...pageProps} />,
                                            ),
                                        )}
                                        <div
                                            data-slot="modal-root-ref"
                                            ref={modalRootRef}
                                        />
                                        <div
                                            data-slot="popover-root-ref"
                                            ref={popoverRootRef}
                                        />
                                    </>
                                }
                                <div />
                            </div>

                            <MissingTranslationsDevTools />
                        </div>
                    </KruuponentProvider>
                </MasterProvider>
            </QueryClientProvider>
        </>
    );
};

const MyApp: React.FC<ExtendedAppProps> = (props) => {
    return <DirtyApp {...props} />;
};

export default nextApi.withTRPC(appWithTranslation(MyApp, nextI18NextConfig));
