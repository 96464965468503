import { Button } from "@kruu-gitlab/kruuponents/button";
import { Modal } from "@kruu-gitlab/kruuponents/modal";
import {
    Table,
    TableHead,
    TableRow,
    TableSection,
} from "@kruu-gitlab/kruuponents/table";
import { clsx } from "clsx";
import { useRouter } from "next/router";
import process from "process";
import { useState } from "react";
import { useStore } from "zustand";

import { Icon } from "~/components/icon";

import { getMissingTranslationUploadButtonId } from "./get-missing-translation-upload-button-id";
import { MissingTranslationRow } from "./missing-trans-row";
import { missingTranslationStore } from "./store";

const MissingTranslationsDevTools: React.FC = () => {
    const { locale } = useRouter();
    const [isModalOpen, setModalOpen] = useState(false);
    const { missingTranslations } = useStore(missingTranslationStore);

    const disableSave = locale != "en";

    if (process.env["NEXT_PUBLIC_I18NEXT_ALWAYS_RETURN_KEY"] == "true") {
        return null;
    }
    return (
        <div className="fixed bottom-0 right-0">
            {!isModalOpen && (
                <Button
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    color="warning"
                    className={clsx(
                        missingTranslations.length == 0 &&
                            "border-none bg-warning/30",
                    )}
                    variant="contained"
                >
                    <Icon icon="la:language" /> Translations (
                    {missingTranslations.length} Missing)
                </Button>
            )}
            <Modal
                slots={{
                    content: {
                        className:
                            "p-0 h-96 w-11/12 sm:w-9/12 md:max-w-2xl bg-surface-weak rounded-md p-4 pt-2",
                    },
                }}
                open={isModalOpen}
                onOpenChange={(isOpen) => {
                    setModalOpen(isOpen);
                }}
            >
                <div className={clsx("flex h-full flex-col")}>
                    <div className="w-full overflow-x-auto">
                        <Table
                            color="surface-inverted"
                            aria-label="employee table"
                        >
                            <TableSection as="tbody">
                                {missingTranslations.map((mt) => {
                                    return (
                                        <MissingTranslationRow
                                            key={`${mt.ns}:${mt.key}`}
                                            disableSave={disableSave}
                                            missingTranslation={mt}
                                        />
                                    );
                                })}
                            </TableSection>
                            <TableSection
                                as="thead"
                                className="whitespace-nowrap"
                            >
                                <TableRow>
                                    <TableHead>Action</TableHead>
                                    <TableHead>Namespace</TableHead>
                                    <TableHead>Key</TableHead>
                                    <TableHead className="w-full">
                                        Default Value
                                    </TableHead>
                                    <TableHead>Reported URLs</TableHead>
                                </TableRow>
                            </TableSection>
                        </Table>
                    </div>
                    <div className="grow" />
                    <div className="flex justify-end">
                        {disableSave ? (
                            <div>
                                <p>
                                    Our translate tool is cofigured to
                                    autotranslate other languages based on
                                    English. Therefore you should add
                                    translation of missing keys to English.
                                </p>
                                <p>
                                    Please switch to English to enable saving.
                                </p>
                            </div>
                        ) : (
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={disableSave}
                                onClick={() => {
                                    missingTranslations.forEach((mt) => {
                                        const button = document.getElementById(
                                            getMissingTranslationUploadButtonId(
                                                mt,
                                            ),
                                        );
                                        if (button) {
                                            button.click();
                                        }
                                    });
                                }}
                            >
                                {locale == "en"
                                    ? "Save All"
                                    : "Please switch to English"}
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export { MissingTranslationsDevTools };
