import { useQueryClient } from "@tanstack/react-query";
import axios, { AxiosInstance } from "axios";
import { ClientError, GraphQLClient } from "graphql-request";
import { createContext, useContext, useMemo, useState } from "react";
import { z } from "zod";

import { invalidateUseUser } from "~/modules/use-user";

import { getAuthToken } from "../../../core/utils/auth";

const REST_BASE_URL = process.env["NEXT_PUBLIC_API_URL"];
const GRAPHQL_BASE_URL = process.env["NEXT_PUBLIC_GRAPHQL_URL"];

const urlSchema = z.string().url();

type ContextType = {
    axios: AxiosInstance;
    gql: GraphQLClient;
};

const NetworkClientContext = createContext<ContextType>({
    axios: axios.create({
        baseURL: urlSchema.parse(REST_BASE_URL),
    }),
    gql: new GraphQLClient(urlSchema.parse(GRAPHQL_BASE_URL)),
});

interface Props {
    children?: React.ReactNode;
    axios?: ContextType["axios"];
    gql?: ContextType["gql"];
}

const NetworkClientProvider: React.FC<Props> = (props) => {
    const queryClient = useQueryClient();

    const [localAxiosInstance] = useState(() => {
        const axiosInstance = axios.create({
            baseURL: urlSchema.parse(REST_BASE_URL),
        });

        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                const { status } = error.response;
                if (status == 401) {
                    invalidateUseUser(queryClient);
                }
                return Promise.reject(error);
            },
        );

        axiosInstance.interceptors.request.use(
            function (config) {
                const token = getAuthToken();

                config.headers.set("authorization", `Bearer ${token}`);

                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );

        return axiosInstance;
    });
    const [localgqlInstance] = useState<GraphQLClient>(() => {
        return new GraphQLClient(urlSchema.parse(GRAPHQL_BASE_URL), {
            headers: () => {
                const token = getAuthToken();

                const headers: Record<string, string> = {};

                if (token) {
                    headers["authorization"] = `Bearer ${token}`;
                }

                return headers;
            },
            responseMiddleware: (e) => {
                let unauthenticated = false;
                let isCurrentUserQuery = false;
                if (e instanceof ClientError && e.response.status == 401) {
                    unauthenticated = true;
                    /*
            ** IMPORTANT **
            if you change the query key from "getCurrentUser" to sth else
              please don't forget to update the check in
              "src/modules/use-user/__internals__/query-fn.ts"
          */
                    if (
                        e.request.query
                            .toString()
                            .includes("query getCurrentUser")
                    ) {
                        isCurrentUserQuery = true;
                    }
                }
                if ("status" in e && e.status == 401) {
                    unauthenticated = true;
                }

                if (unauthenticated && !isCurrentUserQuery) {
                    invalidateUseUser(queryClient);
                }
            },
        });
    });

    const contextValue = useMemo((): ContextType => {
        return {
            axios: props.axios || localAxiosInstance,
            gql: props.gql || localgqlInstance,
        };
    }, [props.axios, props.gql, localAxiosInstance, localgqlInstance]);

    return (
        <NetworkClientContext.Provider value={contextValue}>
            {props.children}
        </NetworkClientContext.Provider>
    );
};

function useNetworkClient(): ContextType {
    const client = useContext(NetworkClientContext);
    if (client == null) {
        throw new Error(
            "NetworkClientContext not found. Did you forget to wrap your components with NetworkClientProvider?",
        );
    }

    return client;
}

export { NetworkClientProvider, useNetworkClient };
