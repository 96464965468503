import { useMediaQuery } from "usehooks-ts";

function useMqTheme(): "light" | "dark" {
    const prefersDark = useMediaQuery("(prefers-color-scheme: dark)");
    if (prefersDark) {
        return "dark";
    } else {
        return "light";
    }
}

export { useMqTheme };
