import { createContext, useContext } from "react";

const ModalRootRefContext = createContext<React.RefObject<HTMLElement> | null>(
    null,
);

interface Props {
    children?: React.ReactNode;
    modalRootRef: React.RefObject<HTMLElement>;
}

const ModalRootRefProvider: React.FC<Props> = ({ modalRootRef, children }) => {
    return (
        <ModalRootRefContext.Provider value={modalRootRef}>
            {children}
        </ModalRootRefContext.Provider>
    );
};

function useModalRootRef(): React.RefObject<HTMLElement> {
    const rootRef = useContext(ModalRootRefContext);
    if (rootRef == null) {
        throw new Error(
            "ModalRootRefContext not found. Did you forget to wrap your components with ModalRootRefProvider?",
        );
    }
    return rootRef;
}

export { ModalRootRefProvider, useModalRootRef };
